<template>
  <div class="row mt5">
    <div class="col-6" v-for="item in data" :key="item.fieldSetKey" v-show="userCanViewLoanAccount">
      <div class="account_item_head">
        <p class="account_item_head_text text--white">
          {{ item.fieldSetName }}
        </p>
      </div>
      <div class="row no-gutters account_item align-items-start">
        <div
          class="col-6 p-4"
          v-for="item2 in item.fields"
          :key="item2.fieldKey"
        >
          <span
            class="
              text--black text--opaq2 text--small text--bold
              account_item_title
            "
          >
            {{ item2.fieldName }}
            <a
              class="text--primary text--capital account_item_head_text mr-3"
              v-if="userCanEditLoanAccount"
              @click="
                editField(
                  item.fieldSetKey,
                  item2.fieldKey,
                  item2.value,
                  item.fields
                )"
              >edit</a
            >
          </span>
          <span
            v-if="['customer link', 'group link', 'user link'].includes(item2.dataType.toLowerCase())"
            class="
              table__body__text table__text
              text--capital text--primary
              cursor
              account_item_subtitle
            "
            style="cursor:pointer; word-wrap: break-word"
            @click="routeToLinkView(item2.value, item2.dataType)"
          >
            {{
              (item2.value && item2.value.length) ? item2.value.split("-").at(-1) : ""
            }}
          </span>
          <span
            v-else
            class="text--black text--regular account_item_subtitle"
            style="word-wrap: break-word;"
          >
            {{ item2.value }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: Array,
    reload: Function,
    userCanEditLoanAccount: Boolean,
    userCanViewLoanAccount: Boolean,
  },
  methods: {
    editField(key, k, v, d) {
      this.$emit("editField", key, k, v, d);
    },
    newField(key, k) {
      this.$emit("newField", key, k);
    },
    routeToLinkView(key, type) {
      const link = key.split("-").at(-2);
      if(type.toLowerCase() == "customer link") {
        this.$router.push({ path: `/customer/${link}` })
      }
      if(type.toLowerCase() == "group link") {
        this.$router.push({ path: `/group/${link}` })
      }
      if(type.toLowerCase() == "user link") {
        this.$router.push({ path: `/user/${link}` })
      }
    },
  },
};
</script>
